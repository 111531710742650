import React from "react";
import Texts from "../../utils/texts";
import { clsTemplate } from "../../utils/className";
import TitleText from "../TitleText";
import StayInformedButton from "../StayInformedButton";
import Logo from "../Logo";
import "./IntroPage.css";

export default function IntroPage() {
  return (
    <div className={cls`wrapper`}>
      <Logo className={cls`logo`} size="14vmin" />
      <TitleText className={cls`title`}>
        <span>{Texts.page_one_title_line_one}</span>
        <span>{Texts.page_one_title_line_two}</span>
      </TitleText>
      <span className={cls`subtitle`}>
        {Texts.good_business_practice}
      </span>
      <StayInformedButton />
    </div>
  );
}

const cls = clsTemplate("IntroPage");
