import React from "react";

export default function useToggle<S, T>(params: {
  alternate?: T;
  initial?: S;
  isOnInitially?: boolean;
} = {}) {
  const { alternate, initial, isOnInitially } = params;

  const [isOn, setIsOn] = React.useState(isOnInitially ?? true);
  const toggle = React.useCallback(function toggle() {
    setIsOn((s) => !s);
  }, []);

  return {
    isOn,
    value: isOn ? initial : alternate,
    toggle,
  };
}
