import React from "react";
import { clsTemplate } from "../utils/className";
import Texts from "../utils/texts";
import ModalButton from "./ModalButton";
import StayInformedModal from "./StayInformedModal";
import "./StayInformedButton.css";

export default function StayInformedButton() {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const toggleModal = React.useCallback(() => buttonRef.current?.click(), []);

  return (
    <ModalButton
      className={cls``}
      htmlRef={buttonRef}
      popupContent={<StayInformedModal toggle={toggleModal} />}
    >
      {Texts.stay_informed_btn}
    </ModalButton>
  );
}

const cls = clsTemplate("StayInformedButton");
