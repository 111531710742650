import React from "react";
import { classes, clsTemplate } from "../utils/className";
import "./Modal.css";

export default function Modal(
  props: { toggle: () => void } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  const { toggle, ...wrapperProps } = props;

  // animate modal fade out by adding a hidden class transition
  const fadeOut = React.useCallback(
    function (e: React.MouseEvent) {
      (e.target as HTMLDivElement).classList.add("hidden");
      setTimeout(toggle, 300);
    },
    [toggle]
  );

  return (
    <div
      {...wrapperProps}
      className={classes(cls`wrapper`, props.className)}
      onClick={fadeOut}
    >
      <div className={cls`content`} onClickCapture={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  );
}

const cls = clsTemplate("Modal");
