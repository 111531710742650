import React from "react";
import { classes, clsTemplate } from "../utils/className";
import useToggle from "./hooks/useToggle";

export default function ModalButton(
  props: {
    htmlRef?: React.MutableRefObject<HTMLButtonElement | null>;
    popupContent?: React.ReactNode;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) {
  const { className, htmlRef, popupContent, ...buttonProps } = props;
  const { isOn, toggle } = useToggle({ isOnInitially: false });

  return (
    <>
      <button
        {...buttonProps}
        className={classes(cls`btn`, className)}
        onClick={toggle}
        ref={htmlRef}
      />
      {isOn ? popupContent : null}
    </>
  );
}

const cls = clsTemplate("ModalButton");
