import React from "react";

export default function useOnNavHashChanged(
  callbackFn: (arg0: string) => void,
  timeoutMs: number = 300
) {
  const [renderCount, setRenderCount] = React.useState(0);
  React.useEffect(() => {
    setTimeout(() => setRenderCount(renderCount + 1), timeoutMs);
  }, [renderCount, timeoutMs]);
  React.useEffect(() => {
    callbackFn(window.location.hash.replace("#", ""));
  });
}
