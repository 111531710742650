import React, { Suspense } from "react";
import { clsTemplate } from "../utils/className";
import withAppWrapper from "./hocs/withAppWrapper";
import ErrorResetBoundary from "./ErrorResetBoundary";
import Spinner from "./Spinner";
import SiteHeader from "./SiteHeader";
import FullPageScroller from "./FullPageScroller";
import IntroPage from "./pages/IntroPage";
import FeaturesPage from "./pages/FeaturesPage";
import JoinUpPage from "./pages/JoinUpPage";
import "./App.css";

function App() {
  const [pageIdx, setPageIdx] = React.useState(0);

  return (
    <div className={cls``}>
      <ErrorResetBoundary>
        <Suspense fallback={<Spinner />}>
          <SiteHeader hidden={pageIdx === 0} className={`page-color page-color-${pageIdx}`} />
          <FullPageScroller onScrollPage={setPageIdx}>
            <IntroPage />
            <FeaturesPage />
            <JoinUpPage />
          </FullPageScroller>
        </Suspense>
      </ErrorResetBoundary>
    </div>
  );
}

const cls = clsTemplate("App");

export const AppRoot = withAppWrapper(App);

export default App;
