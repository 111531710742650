import React from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { classes, clsTemplate } from "../utils/className";
import { PAGE_HASH_TO_INDEX } from "../utils/constants";
import "./FullPageScroller.css";
import useOnNavHashChanged from "./hooks/useOnNavHashChanged";

export default function FullPageScroller(
  props: {
    onScrollPage: (currentPageIndex: number) => void;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  const { onScrollPage } = props;
  const [pageIdx, setPageIdx] = React.useState(0);

  // the minimum and maximum scroll index
  const pageCount = React.Children.count(props.children);
  const scrollBounds = {
    min: 0,
    max: pageCount - 1,
  };

  const updatePageIdx = React.useCallback(
    function updatePageIdx(dy: number) {
      setPageIdx((pageIdx) => {
        const newPageIdx = Math.max(
          Math.min(pageIdx + dy, scrollBounds.max),
          scrollBounds.min
        );
        if (newPageIdx !== pageIdx) {
          onScrollPage(newPageIdx);
        }
        return newPageIdx;
      });
    },
    [onScrollPage, scrollBounds.max, scrollBounds.min]
  );

  React.useEffect(
    function syncPageHash() {
      window.location.hash = PAGE_HASH_TO_INDEX[pageIdx];
    },
    [pageIdx]
  );
  useOnNavHashChanged((pageHash) => {
    const pageHashIndex = PAGE_HASH_TO_INDEX.findIndex(
      (hash) => hash === pageHash
    );
    if (pageHashIndex !== pageIdx) {
      updatePageIdx(pageHashIndex - pageIdx);
    }
  });

  const isLastPage = pageIdx >= scrollBounds.max;
  const colorCls = `page-color page-color-${pageIdx}`;

  return (
    <ReactScrollWheelHandler
      className={cls`wrapper`}
      upHandler={() => updatePageIdx(-1)}
      downHandler={() => updatePageIdx(1)}
    >
      <div className={cls`content`}>
        {React.Children.map(props.children, (node, i) => (
          <ScrollItem index={i} page={pageIdx}>
            {node}
          </ScrollItem>
        ))}
      </div>
      <ScrollArrow
        className={classes(cls`arrow`, colorCls, isLastPage ? "hidden" : "")}
        onClick={() => updatePageIdx(+1)}
      />
      <ScrollPosition
        className={classes(colorCls)}
        pageIndex={pageIdx}
        pageCount={pageCount}
        updatePageIdx={updatePageIdx}
      />
    </ReactScrollWheelHandler>
  );
}

const cls = clsTemplate("FullPageScroller");

function ScrollItem(
  props: { index: number; page: number } & React.PropsWithChildren
) {
  const { index, page } = props;
  const marginTopVh = index === 0 ? -100 * page : 0;
  return (
    <div className={cls`item`} style={{ marginTop: `${marginTopVh}vh` }}>
      {props.children}
    </div>
  );
}

function ScrollArrow(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  return (
    <div {...props}>
      <MdOutlineKeyboardArrowDown size={"5vmin"} />
    </div>
  );
}

function ScrollPosition(
  props: {
    pageCount: number;
    pageIndex: number;
    updatePageIdx: (i: number) => void;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  const { pageCount, pageIndex, updatePageIdx, ...divProps } = props;
  return (
    <div
      {...divProps}
      className={classes(cls`scroll_position`, props.className)}
    >
      {new Array(pageCount).fill(null).map((_, idx) => {
        return (
          <span
            className={classes(
              `indicator`,
              pageIndex === idx ? "active" : "",
              `page-bg page-bg-${pageIndex}`
            )}
            key={idx}
            onClick={() => updatePageIdx(idx - pageIndex)}
          ></span>
        );
      })}
    </div>
  );
}
