import React from "react";
import Logo from "./Logo";
import { classes, clsTemplate } from "../utils/className";
import "./SiteHeader.css";

export default function SiteHeader(
  props: { hidden?: boolean } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  return (
    <div
      {...props}
      className={classes(
        cls`wrapper`,
        props.hidden ? " hidden" : "",
        props.className,
      )}
    >
      <Logo className={cls`logo`} data-testid="logo" size="5.4vmin" />
      <Copyright />
    </div>
  );
}

const cls = clsTemplate("SiteHeader");

function Copyright() {
  return (
    <span className={cls`owner`}>
      All rights reserved &copy; {new Date().getFullYear()}
    </span>
  );
}
