export const Site = {
  contact: {
    email: "info@ensured.ng",
    fb: "https://web.facebook.com/profile.php?id=61559932250799",
    ig: "https://www.instagram.com/ensuredng/",
    in: "https://www.linkedin.com/company/102945076/",
    x: "https://twitter.com/ensuredng",
  },
  name: "Ensured.ng",
  get url() {
    return `https://${this.name.toLowerCase()}`;
  },
};

export const Colors = {
  DARK: "var(--color-bg)",
  LIGHT: "var(--color-fg)",
} as const;

export const FETCH_STATUS = {
  FAILURE: "error",
  SUCCESS: "completed",
  WAITING: "pending",
} as const;
export type FetchStatus = (typeof FETCH_STATUS)[keyof typeof FETCH_STATUS];

export const PAGE_HASH_TO_INDEX = ["intro", "features", "contact"] as const;
