import React from "react";

function randomRange(min: number, max: number) {
  return min + Math.random() * (max - min);
}

export default function AutoTypeText({
  initialTypeDelayMs = AutoTypeText.INITIAL_TYPE_DELAY_DEFAULT_MS,
  typeDelayMinMs = AutoTypeText.TYPE_DELAY_MIN_DEFAULT_MS,
  typeDelayMaxMs = AutoTypeText.TYPE_DELAY_MAX_DEFAULT_MS,
  ...props
}: {
  initialTypeDelayMs?: number;
  typeDelayMaxMs?: number;
  typeDelayMinMs?: number;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) {
  const [revealText, setRevealText] = React.useState("");
  const childText = props.children?.toString() ?? "";

  React.useEffect(() => {
    const minTimeout = revealText.length ? 0 : initialTypeDelayMs;
    const timeoutMs = minTimeout + randomRange(typeDelayMinMs, typeDelayMaxMs);
    const timeoutId = setTimeout(() => {
      if (revealText.length < childText.length) {
        setRevealText(childText.substring(0, revealText.length + 1));
      }
    }, timeoutMs);
    return () => clearTimeout(timeoutId);
  }, [
    initialTypeDelayMs,
    childText,
    revealText.length,
    typeDelayMaxMs,
    typeDelayMinMs,
  ]);

  return <span {...props}>{revealText}</span>;
}

AutoTypeText.INITIAL_TYPE_DELAY_DEFAULT_MS = 1000;
AutoTypeText.TYPE_DELAY_MAX_DEFAULT_MS = 115;
AutoTypeText.TYPE_DELAY_MIN_DEFAULT_MS = 25;
