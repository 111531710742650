import React from "react";
import { classes, clsTemplate } from "../utils/className";
import AutoTypeText from "./AutoTypeText";
import "./Logo.css";
import { PAGE_HASH_TO_INDEX } from "../utils/constants";

export default function Logo({
  size,
  className,
  ...wrapperProps
}: { size: string } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return (
    <div className={classes(className, cls`wrapper`)} {...wrapperProps}>
      <a href={`#${PAGE_HASH_TO_INDEX[0]}`} target="_self">
        <img
          src="logo192.png"
          style={{ width: size }}
          alt="logo"
          className={cls`img`}
        />
        <AutoTypeText
          className={cls`txt`}
          style={{
            fontSize: `calc(${size} / 1.1)`,
            marginLeft: `calc(${size} / -4)`,
            marginTop: `calc(${size} / -10)`,
          }}
        >
          nsured
        </AutoTypeText>
      </a>
    </div>
  );
}

const cls = clsTemplate("Logo");
