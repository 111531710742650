import React from "react";
import { classes, clsTemplate } from "../utils/className";
import Texts from "../utils/texts";
import Modal from "./Modal";
import "./StayInformedModal.css";
import { isValidEmail } from "../utils/validate";

const MIN_NAME_LENGTH = 4;
const Classes = {
  ERROR: "error",
  PENDING: "pending",
  SUCCESS: "success",
};

export default function StayInformedModal(props: Parameters<typeof Modal>[0]) {
  const [submissionStatus, setSubmissionStatus] = React.useState<{
    error?: boolean;
    errorReason?: string;
    pending?: boolean;
    success?: boolean;
  }>({});
  const nameFieldRef = React.useRef<HTMLInputElement>(null);
  const emailFieldRef = React.useRef<HTMLInputElement>(null);
  const modalWrapperRef = React.useRef<HTMLDivElement>(null);

  const onSubmit = React.useCallback(
    function submit() {
      setSubmissionStatus({ pending: true }); // reset submission status

      const submissionParams = {
        email: emailFieldRef.current?.value,
        name: nameFieldRef.current?.value,
      };

      if ((submissionParams.name?.length ?? 0) < MIN_NAME_LENGTH) {
        return setSubmissionStatus({
          error: true,
          errorReason: Texts.error_invalid_name,
        });
      }
      if (!isValidEmail(submissionParams.email)) {
        return setSubmissionStatus({
          error: true,
          errorReason: Texts.error_invalid_email,
        });
      }

      // attempt submission
      addToMailingList(submissionParams)
        .then((result) => {
          if (result.success) {
            const autoDismissModalTimeoutMs = 3000;
            setTimeout(props.toggle, autoDismissModalTimeoutMs);
          } else {
            throw Texts.error_mailing_list_subscribe_failed;
          }
          return setSubmissionStatus(result);
        })
        .catch((error) => {
          setSubmissionStatus({ error: true, errorReason: error.message });
        });
    },
    [props.toggle]
  );

  const submissionClasses = [
    submissionStatus.error ? Classes.ERROR : "",
    submissionStatus.pending ? Classes.PENDING : "",
    submissionStatus.success ? Classes.SUCCESS : "",
  ];

  return (
    <Modal {...props}>
      <div
        className={classes(cls`wrapper`, ...submissionClasses)}
        ref={modalWrapperRef}
      >
        <div className={cls`title`}>{Texts.stay_informed_modal_title}</div>
        <input
          className={cls`form_field`}
          type="text"
          ref={nameFieldRef}
          placeholder={Texts.stay_informed_modal_placeholder_name}
        />
        <input
          className={cls`form_field`}
          type="email"
          ref={emailFieldRef}
          placeholder={Texts.stay_informed_modal_placeholder_email}
        />
        <input
          className={cls`submit_btn`}
          disabled={!!submissionStatus.pending}
          onMouseUp={onSubmit}
          type="button"
          value={Texts.stay_informed_modal_btn}
        />
        <div className={classes(cls`status`, ...submissionClasses)}>
          {submissionStatus.pending
            ? Texts.mailing_list_submission_pending
            : submissionStatus.success
            ? Texts.mailing_list_submission_success
            : submissionStatus.errorReason}
        </div>
      </div>
    </Modal>
  );
}

const cls = clsTemplate("StayInformedModal");

async function addToMailingList(params: { name?: string; email?: string }) {
  // https://api.sender.net/subscribers/add-subscriber/
  const url = new URL("https://api.sender.net/v2/subscribers");
  const senderAccessToken =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZmU3MGZiYmNlMmU4YjJkZDZhNzU4N2Q0ZDk4OTEyYThhYjIxODMxZjAzNGQ0MGRhNDNjODczN2NhMzc3MzY0NmI1ZWVhMzE4NjlkNjliNmUiLCJpYXQiOiIxNzE1MjExMTM2LjcxNDYwMiIsIm5iZiI6IjE3MTUyMTExMzYuNzE0NjEwIiwiZXhwIjoiNDg2ODgxMTEzNi43MTE3MzkiLCJzdWIiOiI4NzU3MDEiLCJzY29wZXMiOltdfQ.kDcW_9DPtROpUuuMdtO0ghzewCDPdgOLDBWWfejwXY51R9Bz_bqnz2LjnpEhzoT2051lVDI-ZyziZyVDZuYR0Q";

  const headers = {
    Authorization: `Bearer ${senderAccessToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  } as const;

  const subscriberGroup = "el83xr";
  const { email, name } = params;
  const [firstname, ...othernames] = name?.split(" ", 2) ?? [];
  const lastname = othernames.join(" ");

  const data = {
    email,
    firstname,
    groups: [subscriberGroup],
    lastname,
    trigger_automation: false,
  } as const;

  const response = await fetch(url, {
    body: JSON.stringify(data),
    headers,
    method: "POST",
  }).then((response) => response.json());

  return { success: true, data: response };
}
