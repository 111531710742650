const Texts = {
  contact_mail_subject: "Tell me more!",
  contact_mail_content: "Hi, my name is",
  error_invalid_email: "Email format is not valid",
  error_invalid_name: "Name must have at least 4 characters",
  error_mailing_list_subscribe_failed: "Failed to add you to mailing list, please try again later.",
  features_section_a_content:
    "Every transaction is a link in an unbroken chain of ownership safeguarding trust against the tide of counterfeits",
  features_section_a_title: "Invoilable Integrity",
  features_section_b_content:
    "With just a swift scan of a code, information on your product’s journey, certifications, and quality standards unfolds before your eyes",
  features_section_b_title: "Verifiable Value",
  features_section_c_content:
    "Any discrepancies are easily reported by consumers and tracked by manufacturers, ensuring collaboration between sellers and buyers to rebuild trust",
  features_section_c_title: "Flaggable Fakes",
  good_business_practice: "just because it is good business practice",
  how_we_do_it: "How we do it?",
  mailing_list_submission_pending: "Working...",
  mailing_list_submission_success: "Thank you! Expect an email from us ❤️",
  page_one_title_line_one: "Pioneering authenticity",
  page_one_title_line_two: "revolutionalising trust",
  page_three_subtitle: "Demand the authenticity you deserve",
  page_three_title_line_one: "Pioneering authenticity",
  page_three_title_line_two: "revolutionalising trust",
  stay_informed_btn: "Stay informed",
  stay_informed_modal_btn: "Join the waitlist",
  stay_informed_modal_placeholder_email: "your@email.com",
  stay_informed_modal_placeholder_name: "Your Name",
  stay_informed_modal_title: "Be first in line",
};

export default Texts;
