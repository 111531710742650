function TryAgain({ onClick }: React.HTMLProps<HTMLDivElement>) {
  return (
    <div style={TryAgain.wrapperStyles} onClick={onClick}>
      Try again
    </div>
  );
}

TryAgain.wrapperStyles = {
  alignSelf: "center",
  border: "solid 0.5vmin var(--color-bg)",
  borderRadius: "1vmin",
  cursor: "pointer",
  height: "4vmin",
  padding: "1vmin 2vmin",
  position: "absolute",
  justifySelf: "center",
  left: "calc(50% - 7vmin)",
  top: "calc(50% - 2vmin)",
  width: "14vmin",
} as React.CSSProperties;

export default TryAgain;
