import React from "react";
import { classes, clsTemplate } from "../utils/className";
import "./TitleText.css";

export default function TitleText(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
) {
  return (
    <h1 {...props} className={classes(cls``, props.className)}>
      {props.children}
    </h1>
  );
}

const cls = clsTemplate("TitleText");
