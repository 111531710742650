import { clsTemplate } from "../../utils/className";
import Texts from "../../utils/texts";
import TitleText from "../TitleText";
import "./FeaturesPage.css";

export default function FeaturesPage() {
  return (
    <div className={cls``}>
      <TitleText>{Texts.how_we_do_it}</TitleText>
      <SectionText title={Texts.features_section_a_title}>
        {Texts.features_section_a_content}
      </SectionText>
      <SectionText title={Texts.features_section_b_title}>
        {Texts.features_section_b_content}
      </SectionText>
      <SectionText title={Texts.features_section_c_title} isEnd>
        {Texts.features_section_c_content}
      </SectionText>
    </div>
  );
}

function SectionText(
  props: { title: string; isEnd?: boolean } & React.PropsWithChildren
) {
  const { children, isEnd = false, title } = props;
  return (
    <div className={cls`section`} is-end={"" + isEnd}>
      <span className={cls`section_title`}>{title}</span>
      <span className={cls`section_text`}>{children}</span>
    </div>
  );
}

const cls = clsTemplate("FeaturesPage");
